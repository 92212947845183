import React, { useState, useContext, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { mediaQueryHOC, 
         customModalInterestitalStyles,
         customStylesInterestitialLegalModal,
         Icon,
         SpotlightWaitlistConstants,
         Constants,
         ContentProperty,
         Heading,
         Button,
         Image,
         CampaignIframeOverlayConstants,
         CheckboxInput,
         mixtureGraphQL,
         mixtureFormRegister,
         filterMethod,
         checkPattern,
         trim,
         interestitialEventPush,
         getCookieValue,
         OnePageScrollContext
        } from './exportComponent';

const MarkdownText = dynamic(() => import('../../MarkdownText/MarkdownText'))

function InterstitialLayout(props) {

    const { document: entity, isMedium, index, eggFound, isLarge } = props;

    const { title,
        body,
        legalText,
        anchorId,
        closeModalLabel,
        mobileBackgroundAsset,
        backgroundAsset,
        thankYouCallToAction,
        TopicTitle,
        rawTopicList,
        descriptionFormFieldText,
        ctaLabel,
        formLabels,
        emailDisclaimerTop,
        isImplicitTCAgree,
        isInterstitialPopup,
        emailExceedLimitLabel,
        thankYouTitleLabel, 
        thankYouBackgroundAsset,
        thankYouDescriptionLabel,
        screen2MobileBackgroundAsset,
        expiryHours,
        backgroundColor,
        modalLabelFirst,
        modalLabelSecond,
        enrichmentSubCopy,
        firstModalLabel,
        secondModalLabel,
        checkBoxText,
        noticeTitleFirst,
        noticeTitleSecond,
        emailDisclaimerText,
        firsEmailDisclaimer,
        secondEmailDisclaimer,
        thirdEmailDisclaimer,
        brushNotSelected,
        checkboxNotSelected
        }  = ContentProperty(entity);   
        
    const blockRef = useRef(null);
    const firstLegalModalRef = useRef(null);

    const context = useContext(OnePageScrollContext);
    const currentIndex = context?.currentIndex || 0;
    const isActiveBlock = (index === currentIndex);

    const [interstitialModalOpen, setIsInterstitialModalOpen] = useState(false);
    const [isThankyouModalOpen, setIsThankyouModalOpen] = useState(false);
    const [selectedBrushType, setSelectedBrushType] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userData, setUserData] = useState({});
    const [erroneousInputs, setErroneousInputs] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState(false);
    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(isImplicitTCAgree);
    const [selectedOptinId, setSelectedOptinId] = useState();
    const [traitsData, setTraitsData] = useState();
    const [optinsData, setOptinsData] = useState();
    const [traitsValue,setTraitsValue] = useState('');
    const [isSecondModalOpen, setIsModalOpen] = useState(false);
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [emailOptInStatus, setEmailOptInStatus] = useState(false);
    const [hasAcceptedEmailDisclaimer, setHasAcceptedEmailDisclaimer] = useState(false);
    const [errorMessageCheckbox, setErrorMessageCheckbox] = useState('');
    const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);

    const isError = erroneousInputs.length > 0;
    
    const registerLegalModalOpen = () => {
        return setTimeout(() => {
            return setLegalModals();
        }, 500)
    };

    const setLegalModals = () => {
        if(blockRef.current) {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
            if (firstModalId) {
                firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }

            let secondModalId = blockRef.current.querySelector('#openSecondLegalModal');
            if (secondModalId) {
                secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        }
    };

    const closeModal = () => {
        closeLegalModal();
        setIsThankyouModalOpen(false);
        setIsInterstitialModalOpen(false);
        setIsModalOpen(false);
        sessionStorage.removeItem(CampaignIframeOverlayConstants.interestitialUserDetail);
        if(isRegistrationSuccess){
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCategory,
                CampaignIframeOverlayConstants?.completeSurveyEvent,
                CampaignIframeOverlayConstants?.eventCloseLabelWithSurveyResponse
            );
        }else{
            interestitialEventPush(
                CampaignIframeOverlayConstants?.eventCloseCategory,
                CampaignIframeOverlayConstants?.eventCloseAction,
                CampaignIframeOverlayConstants?.eventCloseLabelWithoutNoResponse
            );
        } 
    }

    const closeLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);      
    };

    const isCheckboxNotSelected = () => !hasAcceptedTermsAndConditions || !hasAcceptedEmailDisclaimer;

    const handleSubmit = async event => {    
        event.preventDefault();
        setIsSubmitting(true);
        setIsLoader(true);
        const form = event.target;
        let termsAndConditionsChecked;
        if(isImplicitTCAgree) {
            termsAndConditionsChecked = isImplicitTCAgree;
            setHasAcceptedTermsAndConditions(isImplicitTCAgree);
        } else if(!isInterstitialPopup && !document.querySelector('#TermsAndConditions')) {
            termsAndConditionsChecked = document.querySelector('#TermsAndConditions') != null && document.querySelector('#TermsAndConditions').checked;
            setHasAcceptedTermsAndConditions(termsAndConditionsChecked);
        } else {
            termsAndConditionsChecked = hasAcceptedTermsAndConditions;
        }
        
        const inputs = form.querySelectorAll('input, select');
        let errors = [];

        for(let i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove('--error');
            if (inputs[i].hasAttribute('required')) {
                if (inputs[i].value.length === 0) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                } else if (inputs[i].hasAttribute('pattern')) {
                    if (checkPattern(inputs[i])) {
                        errors.push({id: inputs[i].getAttribute('id')});
                        inputs[i].classList.add('--error');
                    }
                }
            } else if (inputs[i].value.length > 0 && inputs[i].hasAttribute('pattern')) {
                if (checkPattern(inputs[i])) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                }
            }
        }

        const email = form?.email?.value;
        if(errors.length === 0 && isInterstitialPopup && email && selectedBrushType && !isCheckboxNotSelected()) {
            const firstName = trim(form?.name?.value);
            let arguObj = {
                firstName: firstName,
                email: email,
                hasAcceptedTermsAndConditions: hasAcceptedTermsAndConditions,
                selectedBrushType: getOptionValue(selectedBrushType),
                traitsData: traitsData,
                optinsData: optinsData,
                emailDisclaimerTop: emailDisclaimerTop,
                firsEmailDisclaimer: firsEmailDisclaimer,
                secondEmailDisclaimer: secondEmailDisclaimer,
                campaignSource: SpotlightWaitlistConstants.interstitalSource
            }
            await mixtureFormRegister(arguObj)
                .then((response) => {
                    if (response?.status === 'SUCCESS') {
                        setErroneousInputs([]);
                        setIsThankyouModalOpen(true);
                        setIsLoader(false);
                        setIsRegistrationSuccess(true);
                        if (typeof (window.dataLayer) !== 'undefined') {
                            interestitialEventPush('event_crm_action', 'event_profile_email_signup', 'interstitial_signup');
                            interestitialEventPush(CampaignIframeOverlayConstants?.eventCategory, CampaignIframeOverlayConstants?.questionSurveyAction, `interstitial_traitBrushType_${selectedBrushType}`);
                        }
                    } else {
                        setIsSubmitting(false);
                        setIsLoader(false);
                        return response?.json();
                    }
                }).then((response) => {
                    if (response && response.status && response.code && response.code === 'ALREADY_PARTICIPATED') {
                        setServerSideErrorMessage(emailExceedLimitLabel);                        
                    }                    
                }).catch((error) => {
                    console.log('error log:', error);
                    setIsSubmitting(false);
                    setIsLoader(false);
                });
            setIsSubmitting(false);
        } else {
            if(!selectedBrushType) {
                setErrorMessage(brushNotSelected);
            }
            if(isCheckboxNotSelected()){
                setErrorMessage(checkboxNotSelected);
            }
            if(errors.length > 0) {
                setErroneousInputs([...errors]);
            }
            setIsSubmitting(false);
            setIsLoader(false)
            return;
        }
    }

    const handleBrushSelection = (selectedBrush) => {
        setErrorMessage('');
        if(selectedBrushType === selectedBrush) {
            setSelectedBrushType('');
            setSelectedOptinId(0);
        } else {
            setTraitsValue(selectedBrush);
            setSelectedBrushType(selectedBrush);            
        }
    };

    const getOptionValue = (selectedBrushType) => {
        for (let i = 0; i < rawTopicList.length; i++) {
            if (rawTopicList[i]?.fields?.value === (selectedBrushType && selectedBrushType.trim())) {
                return rawTopicList[i]?.fields?.value;
            }
        }
        return null;
    }


    const changeUserData = (evt, id) => {
        const userDetail = {...userData, [id]: evt?.target?.value}
        setUserData(userDetail);
        if(isError) {
            const inputElement = document.getElementById(id);
            inputElement.classList.remove('--error');
            const updatedErrorInputs = erroneousInputs.filter(errorInput => errorInput?.id !== id);
            setErroneousInputs(updatedErrorInputs);
        }
        serverSideErrorMessage && setServerSideErrorMessage(false);
        errorMessage && setErrorMessage('');
    }

    const readUserDetails = ()=> {
        return JSON.parse(sessionStorage.getItem(CampaignIframeOverlayConstants.interestitialUserDetail));
    }
    useEffect(() => {
        setTimeout(() => {
            setLegalModals();
        }, 1000)
    },[]);

    useEffect(() => {
        setTimeout(() => {
            if (isInterstitialPopup && !localStorage.getItem(CampaignIframeOverlayConstants.userDataCollectedIntersistialPopUp) && !getCookieValue(CampaignIframeOverlayConstants.disableIntersistialPopUpCookie)) {
                setIsInterstitialModalOpen(true);
                mixtureGraphQL().then((response) => {
                    let traitsObj = filterMethod(response?.consumerActions, 'trait');
                    let optinsObj = filterMethod(response?.consumerActions, 'optIn');
                    setTraitsData(traitsObj);
                    setOptinsData(optinsObj);
                }).catch((error) => {
                    console.error(error);
                })
                const date = new Date();
                date.setHours(parseInt(expiryHours)); // for one day validaity
                const expires = `expires=${date.toUTCString()}`;
                document.cookie = `${CampaignIframeOverlayConstants.disableIntersistialPopUpCookie}=ok; ${expires}; path=/`;
                sessionStorage.removeItem(CampaignIframeOverlayConstants.interestitialUserDetail);
                interestitialEventPush(
                    CampaignIframeOverlayConstants?.eventCategory,
                    CampaignIframeOverlayConstants?.startSurveyEvent,
                    CampaignIframeOverlayConstants?.eventLabel);
                interestitialEventPush(
                    CampaignIframeOverlayConstants?.eventCategory,
                    CampaignIframeOverlayConstants?.questionSurveyAction,
                    CampaignIframeOverlayConstants?.questionSurveyLabel);
            } else {
                setIsInterstitialModalOpen(false);
            }
        }, 10000);
    }, []);

    useEffect(()=> {
        if(serverSideErrorMessage && isInterstitialPopup && isSecondModalOpen) {
            setIsModalOpen(false);
            const userDetails = readUserDetails();
            setUserData(userDetails);
        }
    }, [serverSideErrorMessage]);

    const renderFormLabel = (isFirstStep, ctaText, className = '') => {
        let formLabelsCopy = [];
        formLabelsCopy = isFirstStep ? formLabels?.slice(0, CampaignIframeOverlayConstants.formLabelFirstStep) : formLabels?.slice(CampaignIframeOverlayConstants.formLabelFirstStep, formLabels?.length);
        return (
            <>
                <form className={`ob-spotlight-waitlist__form ${className}`} onSubmit={handleSubmit} noValidate>
                    <ul className={'ob-spotlight-waitlist__form-list'}>
                        {
                            formLabelsCopy?.map((formLabel, formLabelIndex) => (
                                <li className={'ob-spotlight-waitlist__form-list-item'} key={'formLabel-' + formLabelIndex}>
                                    {(
                                        formLabel.fields.type === 'textArea' ?
                                            <textarea cols={51} rows={3}
                                                className={'ob-spotlight-waitlist__form-input'}
                                                placeholder={formLabel.fields.label.fields.text}
                                                id={formLabel?.fields?.id}
                                                value={userData && userData[formLabel?.fields?.id]}
                                                onChange={(evt) => changeUserData(evt, formLabel?.fields?.id)}
                                                maxLength={formLabel?.fields?.maxLength}
                                                name={formLabel?.fields?.id} />
                                            :
                                            <input
                                                type={formLabel.fields.type}
                                                pattern={formLabel?.fields?.validation?.fields?.pattern}
                                                required={formLabel?.fields?.validation?.fields?.required}
                                                id={formLabel?.fields?.id}
                                                name={formLabel?.fields?.id}
                                                value={userData && userData[formLabel?.fields?.id]}
                                                maxLength={formLabel?.fields?.maxLength}
                                                onChange={(evt) => changeUserData(evt, formLabel?.fields?.id)}
                                                className={'ob-spotlight-waitlist__form-input'} placeholder={formLabel.fields.label.fields.text} />
                                    )}

                                    {formLabel.fields.fieldMention &&
                                        <p className={'ob-spotlight-waitlist__form-mention'}>
                                            {formLabel.fields.fieldMention}
                                        </p>
                                    }

                                    {erroneousInputs.length > 0 &&
                                        erroneousInputs.map((error, index) => {
                                            return error.id === formLabel.fields.id && formLabel?.fields?.validation?.fields?.errorMessage &&
                                                <p key={index} className={'ob-spotlight-waitlist__form-error'}>
                                                    {formLabel.fields.validation.fields.errorMessage}
                                                </p>
                                        })
                                    }
                                </li>
                            ))
                        }

                            <li className='ob-interstitial-legal-section'>
                                {(errorMessageCheckbox && !hasAcceptedTermsAndConditions || !hasAcceptedEmailDisclaimer) &&
                                    <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__system-error'}>
                                        {errorMessageCheckbox}
                                    </div>
                                  }
                                {noticeTitleFirst && <div>
                                    <div dangerouslySetInnerHTML={{ __html: noticeTitleFirst }} className={'legal-text'} />
                                </div>}
                                {firsEmailDisclaimer && 
                                    <div className={'ob-interstitial-form-legal-text-checkbox'} >
                                        <CheckboxInput
                                            className={'checkbox-email-disclaimer'}
                                            label={`<p>${firsEmailDisclaimer}</p>`}
                                            name={'emailDisclaimer'}
                                            value={"emailDisclaimer"}
                                            checked={hasAcceptedEmailDisclaimer}
                                            onChange={() => setHasAcceptedEmailDisclaimer(!hasAcceptedEmailDisclaimer)}
                                        />
                                    </div>
                                }
                                {noticeTitleSecond && <div>
                                    <div dangerouslySetInnerHTML={{ __html: noticeTitleSecond }} className={'legal-text'} />
                                </div>}
                                {secondEmailDisclaimer && 
                                    <div className={'ob-interstitial-form-legal-text-checkbox'} >
                                        <CheckboxInput
                                            className={'checkbox-terms-and-conditions'}
                                            label={`<p>${secondEmailDisclaimer}</p>`}
                                            name={"terms-and-conditions"}
                                            value={"terms-and-conditions"}
                                            checked={hasAcceptedTermsAndConditions}
                                            onChange={() => setHasAcceptedTermsAndConditions(!hasAcceptedTermsAndConditions)}
                                        />
                                </div>
                                }
                                {thirdEmailDisclaimer && (
                                    <div className='ob-interstitial-form-legal-text-wrapper'>
                                        <p className='email-disclaimer-text' dangerouslySetInnerHTML={{ __html: thirdEmailDisclaimer }}></p>
                                    </div>
                                )}
                            </li>                            
                       

                        <li className={cx('ob-spotlight-waitlist__form-list-item cta-section', { 'margin-cta-section': isError })} >
                            <Button
                                type={SpotlightWaitlistConstants.submit}
                                className={cx('cta-button')}
                                whiteTheme={true}
                                disabled={isSubmitting}
                                >
                                {`${ctaText}`}
                            </Button>
                            <span>
                                {isLoader && <img src={SpotlightWaitlistConstants.loader} className="loaderImg" />}
                            </span>
                        </li>
                    </ul>
                </form>
            </>
        ) 
    };

    const renderUserDetailForm = () => {
        return (<div
            ref={blockRef} id={anchorId} >
                {isMedium &&
                    <div className={'ob-spotlight-waitlist__mobile-img'}>
                        <Image image={mobileBackgroundAsset} />
                    </div>
                }
                <Image image={(backgroundAsset && !isMedium) ? backgroundAsset : mobileBackgroundAsset} customStyles={{ backgroundSize: 'cover !important', backgroundRepeat: 'no-repeat', padding: '0', minHeight: '940px' }} >
                <div>
                    <div className={'ob-spotlight-waitlist__wrapper'}>
                        <Heading className={'ob-spotlight-waitlist__title'}>{title}</Heading>
                        <div className={'ob-spotlight-waitlist__body'} dangerouslySetInnerHTML={{__html: body}} />
                        <p className={'ob-spotlight-waitlist__label-which-brush-used'}>{thankYouCallToAction}</p>
                        {TopicTitle &&  <div dangerouslySetInnerHTML={{__html:TopicTitle}} className={'ebnrichment-subcopy'}/>}
                        {enrichmentSubCopy &&  <MarkdownText value={enrichmentSubCopy} className={'ebnrichment-subcopy'}/>}
                        {(errorMessage || serverSideErrorMessage) &&
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            <div tabIndex='0' className={'ob-focus-error ob-spotlight-waitlist__system-error'}>
                                {errorMessage || serverSideErrorMessage}
                            </div>
                        }
                        {rawTopicList && 
                            <div className={'ob-spotlight-survey__form-list'}>
                                {
                                    rawTopicList.map((topic, topicIndex) => {
                                        const value = topic?.fields?.value;
                                        return <div className={'ob-spotlight-survey__form-list-item'} key={'formLabel-' + topicIndex}>
                                            <button disabled={isSubmitting} className={`ob-spotlight-survey__brush-type ${value === selectedBrushType ? 'ob-spotlight-survey__brush-type-selected' : ''}`} value={value} onClick={() => handleBrushSelection(value)}>
                                                <Image image={topic?.fields?.icon} />
                                                <span dangerouslySetInnerHTML={{ __html: topic?.fields?.label }} />
                                            </button>
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            descriptionFormFieldText && 
                            <MarkdownText value={descriptionFormFieldText} className={'description-form-field-text'} />
                        }
                        {renderFormLabel(true, ctaLabel?.fields?.text, 'reset')}
                    </div>
                </div>
                </Image>
            </div>
        )
    };

    const renderThankYouScreen = () => {
        return <div ref={blockRef} className={'second-screen'} id={anchorId} >
            <Image image={(thankYouBackgroundAsset && !isMedium) ? thankYouBackgroundAsset : screen2MobileBackgroundAsset} customStyles={{ backgroundSize: 'cover !important', backgroundRepeat: 'no-repeat', padding: '20px', minHeight: '550px' }}>
                <div className={'ob-interstitial-thankyou__wrapper'}>
                    <div className={'ob-interstitial-thankyou__wrapper-inner'}>
                        <Heading className={'ob-interstitial-thankyou__title'}>{thankYouTitleLabel}</Heading>
                        {thankYouDescriptionLabel && <p className={'ob-interstitial-thankyou__body'} dangerouslySetInnerHTML={{ __html: thankYouDescriptionLabel }} />}
                    </div>
                </div>
            </Image>
        </div>
    }

    if(isActiveBlock) {
        setLegalModals();
    }

    return <div className="ob-campaignoverlay">
                <Modal
                    isOpen={interstitialModalOpen} 
                    contentLabel={anchorId} 
                    aria={{ modal: true }} 
                    portalClassName="interestitial-popup"
                    onAfterOpen={registerLegalModalOpen}
                    onRequestClose={closeModal} closeTimeoutMS={250} 
                    style={customModalInterestitalStyles(isMedium)}>
                        <div className='ob-interstitial-popup'>
                            <button aria-label={closeModalLabel} onClick={closeModal} className={'ob-spotlight-waitlist__close-modal'} type='button'>
                                <Icon name={SpotlightWaitlistConstants.close} size='1.4' color={Constants.white} />
                            </button>
                            {
                                isThankyouModalOpen ? renderThankYouScreen() : renderUserDetailForm()
                            }
                        </div>
                </Modal>
                {(firstModalLabel || secondModalLabel) &&
                    <Modal
                        isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                        style={customStylesInterestitialLegalModal(isMedium, backgroundColor)}
                        onRequestClose={closeLegalModal}
                        closeTimeoutMS={250}
                        overlayClassName={isFirstLegalModalOpen ? 'event_button_click first' : 'event_button_click second'}
                        ref={firstLegalModalRef}
                        portalClassName="interestitial-popup-legal"
                    >
                        <div className='ob-spotlight-waitlist__modal-container-legal'>

                            {isFirstLegalModalOpen && firstModalLabel &&
                                <p dangerouslySetInnerHTML={{__html: firstModalLabel}}></p>
                            }

                            {isSecondLegalModalOpen && secondModalLabel &&
                                <p dangerouslySetInnerHTML={{__html: secondModalLabel}}></p>
                            }

                            <button className='event_close_window ob-modal-close'
                                onClick={closeLegalModal}
                                aria-label={closeModalLabel} type='button'>
                                <Icon name='close' size='2.4' color='#000' />
                            </button>

                        </div>
                    </Modal>
                }
    </div>  
}

export default mediaQueryHOC(InterstitialLayout);

InterstitialLayout.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool,
    index: PropTypes.any
};